<template>
  <b-card-code title="Tipos de Parceiro">
    <b-row>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Cód. Tipo Parceiro:"
          
        >
          <b-form-input
            id="codTipoParceiro"
            v-model="FiltroTela.codTipoParceiro"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <br>
        <div class="DivBotaoLadoLado">
          <b-button
            variant="primary"
            type="button"
            @click="ConsultaTipoParceiro"
          >
            <span>
              BUSCAR
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
      <template #cell(comentglobal)="row">
        <b-col xl="12">
          <b-form-textarea
            id="comentglobal"
            v-model="row.value"
            placeholder=""
            rows="3"
            :readonly="'readonly'"
          />
        </b-col>
      </template>
      <template #cell(data)="row">
        {{ TextFormat(row.value, 'date') }}
      </template>
    </b-table>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import FiltrosPortal from '@/customPortal/FiltrosPortal.vue'

import {
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCardCode,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BPagination,
    BCardBody,
    BCol,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'acessoParceiro', label: 'A' },
        { key: 'codTipoParceiro', label: 'Cód Tipo Parceiro', sortable: true },
        { key: 'tipoParceiro', label: 'Tipo Parceiro', sortable: true },
        { key: 'qtdParceiro', label: 'Qtd. Parceiros', sortable: true },
      ],
      /* eslint-disable global-require */
      items: [
      ],
    }
  },
  mounted() {
    // Seta q quantidade de Linhas no Grid
    this.totalRows = this.items.length
    // Consulta Dados para Filtro na Tela
    this.ConsultaTipoParceiro()
    this.DescarregarFiltroTela()
  },
  methods: {
    async ConsultaTipoParceiro() {
      let notification = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/skParceiroHomologado/ListTipoParceiro?CODUSU=${usuario.codusu}`
      this.CarregaFiltroTela()
      await apiPortalSky.post(URLpost, 'this.codTipoParceiro').then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.items = response.data
            this.totalRows = this.items.length
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })
    },
    CarregaFiltroTela() {
      const usuario = this.$session.get('usuario')
      usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
      this.$session.set('usuario', usuario)
    },
    DescarregarFiltroTela() {
      const usuario = this.$session.get('usuario')
      const Filtrado = FiltrosPortal.RetornaFiltro(usuario, this.FiltroTela.TitloTela)
      if (Filtrado.Selecionado) {
        // Realiza Validacao de Campo da Tela para Executar Realmente o Filtro
        if (Filtrado.codTipoParceiro !== '') {
          this.FiltroTela = Filtrado// Carrega o Filtro
          
          // Descarrega Filtro Complementar
          //this.FiltroTela.FiltroComplementar.forEach(ftComplementar => {
          //  switch (ftComplementar.parametro) {
          //    case "":
          //      break
          //     
          //  }

          this.ConsultaTipoParceiro() // Executa o Filtro
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tree.scss";
</style>
