<script>
export default {
  components: {
  },
  AdcionarFiltro(usuario, FiltroTela) {
    const filtrosTela = []
    usuario.filtrosTela.forEach(Filtro => {
      if (Filtro.TitloTela !== FiltroTela.TitloTela) {
        filtrosTela.push(Filtro)
      }
    })
    filtrosTela.push(FiltroTela)
    return filtrosTela
  },
  RetornaFiltro(usuario, Titlo) {
    let FiltroSelecionado = {
      Selecionado: false,
    }
    usuario.filtrosTela.forEach(Filtro => {
      if (Filtro != null) {
        if (Filtro.TitloTela != null) {
          if (Filtro.TitloTela === Titlo) {
            FiltroSelecionado = Filtro
            FiltroSelecionado.Selecionado = true
          }
        }
      }
    })
    return FiltroSelecionado
  },
}

</script>
